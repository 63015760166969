<template>
  <main>
    <a-page-header
      style="border: 1px solid rgb(235, 237, 240)"
      title="Рассылка сообщений пользователям"
    />
    <a-form
      :form="form"
      @submit="handleSubmit"
      style="padding: 20px"
      layout="vertical"
    >
      <a-form-item label="Текст">
        <a-input
          @change="handleInputChange"
          placeholder="Текст уведомления"
          v-decorator="[
            'text',
            {
              rules: [
                { required: true, message: 'Поле текст не может быть пустым!' },
              ],
            },
          ]"
        />
      </a-form-item>
      <a-form-item>
        <a-button v-if="broadcast_ok" type="primary" disabled="">
          Отправлено
        </a-button>
        <a-button v-else type="primary" html-type="submit" :loading="loading">
          {{ loading == false ? "Отправить" : "Отправка" }}
        </a-button>
        <div v-if="errorMessage">
          <span>{{ errorMessage }}</span>
        </div>
      </a-form-item>
    </a-form>
  </main>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      form: this.$form.createForm(this, { name: "coordinated" }),
      broadcast: {
        message: null,
      },
      errorMessage: null,
      loading: false,
      broadcast_ok: false,
    };
  },
  methods: {
    handleInputChange() {
      this.broadcast_ok = false;
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true;
          console.log("Received values of form: ", values.text);
          axios({
            method: "POST",
            url: "/cp/broadcast/",
            data: { text: values.text },
          })
            .then((response) => {
              this.broadcast_ok = true;
              console.log(response);
              this.errorMessage = null;
            })
            .catch((error) => {
              console.log(error);
              this.broadcast_ok = false;
              this.$router.push("/login");
              // this.errorMessage = error.;
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>
